/*
*	MAP
*
*	Styles for map screen and field adding maps
*
*/

@sidebar-width: 210px;
@edit-width: 340px;
@bottom-bar-height: 110px;

// generic control styles
.google-maps-ui {
	.ui-control();
	background: white;
	border: 1px solid @smoke-40;
	color: @text-color-primary;
	font-weight: 500;
	font-size: @font-size-m;
}
.google-maps-ui-shadow {
	.box-shadow( 0 2px 6px hsla(0,0%,0%,0.3) );
}
.google-maps-ui-inner-shadow {
	.box-shadow(inset 0 -2px 0px hsla(0,0%,0%,0.1) );
}


// map page
.leaflet-left .leaflet-control {
	margin-left: 1rem;
}

.leaflet-top {
	top: @header-height;
}

.leaflet-top .leaflet-control {
	margin-top: 1rem;
}

.leaflet-top {
	&.leaflet-right {
		display: flex;
		flex-direction: row-reverse;

		@media only screen and (max-width: 37.5em) {
			align-items: flex-end;
			flex-direction: column;
		}
	}
}

.leaflet-bottom .leaflet-control {
	margin-bottom: 1rem
}

.leaflet-right .leaflet-control {
	margin-right: 1rem;
}


// NO LOCATION BLANK SLATE

.map-backdrop {
	background: @clear-smoke-70;
}

.map-helper-backdrop {
	.map-backdrop;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}



// MAP WRAPPER (FOR POSITIONING AND OVERFLOW HANDLING)

#map-wrapper {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.search-container {
	.google-maps-ui;
	.google-maps-ui-shadow;
	height: 34px;
	border-radius: 3px;
	transition: all 0.2s ease-in-out;
	position: relative;

	.search-icon {
		.google-maps-ui-inner-shadow;
		background: white;
		border-left: 1px solid @smoke-80;
		border-radius: 0 3px 3px 0;
		// border-radius: 2px;
		cursor: pointer;
		line-height: 1;
		// line-height: 20px;
		padding: 5px;
		position: absolute;
		right: 0px;
		top: 0px;
		// z-index: 30001; // for "no fields" condition
		// z-index: 500;
		// z-index: 500;

		&.blue {
			background: @blue;
			color: white;
		}

		img {
			width: 20px;
			opacity: 0.5;
		}
		&:hover img {
			opacity: 0.7;
		}
	} // end search icon

	.search-input {
		border: none;
		line-height: 16px;
		padding: 8px 10px;
		padding-right: 30px;
		border-radius: 3px;
		color: @text-color-primary;
		.google-maps-ui-inner-shadow;
		width: 100%;
		opacity: 1;
		z-index: 30000; // for "no fields" condition

	} // end search input

	@media print {
		display: none;
	}
}

// BIG MAP CONTAINER

#map-container {
	overflow: hidden;
	position: absolute;
	transition: all .3s ease-in-out;
	transition-property: top, left, bottom, right, width;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	// google map
	#map_canvas {
		height: 100%;
		z-index: 0;

		#map-type-control {
			margin-top: 18px;
			margin-right: 18px;
		}

	}

	// helper text that always shows during field add process
	.map-notification-wrapper {
		clear: none;
		margin-left: 1rem;
		@media print {
			display: none;
		}
	}

	.exit-button {
		clear: none;
		margin-left: 20px;
	}

	.map-notification {
		text-align: center;
		z-index: 310;
		padding: .66em 1em;
		background: @white;
		color: @text-color-primary;
		font-size: @font-size-xl;
		line-height: 1;
		font-weight: 500;
		.transition(top .6s ease);
		border-radius: 3px;
		//white-space: nowrap;
		.box-shadow(0 2px 2px @clear-smoke-30);
		max-width: 300px;

		a {
			color: @text-color-primary;
			text-decoration: underline;
		}

		.sub {
			clear: both;
			font-size: @font-size-m;
			margin: 8px 0 0;
		}

		&.shown {
		}
		.cancel {
			cursor: pointer;
			text-decoration: underline;
		}

		&.map-activity-helper {
			line-height: 1.2em;

			img {
				border: 2px solid @separator-color;
				margin-top: 10px;
			}
		}
	}

	// helper window that shows on location when first adding fields
	.map-helper {

		@map-helper-color: @light-yellow;

		padding: 1em;
		background: @map-helper-color;
		color: @text-color-primary;
		font-size: @font-size-xl;
		line-height: 1.4;
		.transition(top .6s ease);
		border-radius: 3px;
		.box-shadow(0 2px 2px @clear-smoke-30);
		width: 300px;
		position: relative;
		top: 40px;
		font-weight: bold;
		border-top: none;

		z-index: 1001;

		&:after {
			.before-after();
			border-color: @map-helper-color transparent;
			border-style: solid;
			border-width: 0 8px 8px;
			top: -8px;
			right: 50%;
			margin: 0 0 0 -4%;
		}

		.sub {
			clear: both;
			font-size: @font-size-l;
			margin: 8px 0 0;
			font-weight: 500;
		}

		.video-wrapper {
			margin-top: 1em;
		}

		.btn {
			margin-top: 1em;
		}

	}


	// MAP CONTROLS
	.leaflet-control {
		border: none;
		clear: none;

		.leaflet-control-zoom-in,
		.leaflet-control-zoom-out {
			.google-maps-ui();
			background: white;
			font-weight: bold;
			font-size: @font-size-l;
			margin-top: -1px;
			color: @text-color-primary;

			&:active {
				background: @smoke-93;
				color: @smoke-20;
				.box-shadow( inset 0 2px 0 rgba(0,0,0,0.1) );
			}
			@media print {
				display: none;
			}
		}
		.leaflet-control-zoom-in {
			.box-shadow(inset 0 -2px 0px hsla(0,0%,0%,0.04) );
		}
		.leaflet-control-zoom-out {
			.google-maps-ui-inner-shadow;
			border-top: 1px solid @smoke-80;
		}

		&.map-type-pickers {
			.google-maps-ui-shadow;
		}
		&.map-type-pickers,
		&.map-notification-wrapper {
			float: right;
			clear: none;
			border-radius: 3px;

			.btn {
				padding: 6px 10px;
				font-size: @font-size-s;
				background: white;
				.google-maps-ui-inner-shadow;
				.google-maps-ui;

				&:hover {
					background: #f5f5f5;
				}
				&.active {
					background: @smoke-93;
					color: @smoke-20;
					.box-shadow( inset 0 2px 0 rgba(0,0,0,0.1) );
				}

				&.add-field {
					padding: 8px 8px 7px;
				}

				&.farm-bounds-icon,
				&.locate-me-icon {
					padding: 3px 7px 6px 7px;

					img {
						width: 16px;
						height: 16px;
						position: relative;
						top: 3px;
					}
				}

			}
		} // end type-pickers

		&.map-type-pickers .btn {
			&:first-child {
				border-right: 1px solid @smoke-70;
			}
			&:last-child {
				border-left: 1px solid @smoke-70;
			}
		}

	}

	// year picker on the map

	.year-picker-button {
		.ui-control();
		border: none;
		height: 34px;
		.google-maps-ui-shadow;
		padding: 0 24px;

		.nav,
		.year {
			.google-maps-ui;
			.google-maps-ui-inner-shadow;
			padding: 7px 0.5em;
			font-size: @font-size-xl;
			font-weight: 400;
			cursor: pointer;

			&:hover {
				background: #f5f5f5;
			}
			&.active {
				background: @smoke-93;
				color: @smoke-20;
				.box-shadow( inset 0 2px 0 rgba(0,0,0,0.1) );
			}
		}

		.nav {
			position: absolute;

			&.left {
				border-right: 1px solid @smoke-80;
			}
			&.right {
				border-left: 1px solid @smoke-80;
			}
		}
		.year {
			border-left: 1px solid @smoke-80;
		}

	}
}

.bottom-bar-field-edit {
	background: white;
	bottom: -@bottom-bar-height;
	height: @bottom-bar-height;
}


// BADGES
// crop letter abbreviations inside a colored circle to show field location

.map-badge {
	width: 30px;
	height: 30px;
	border-radius: 100px;
	display: block;
	cursor: pointer;
	text-shadow: 0 1px 1px hsla(0, 100%, 100%, 0.3);
	text-align: center;
	font-size: @font-size-s;
	line-height: 30px;
	font-weight: 500;
}

.elevator-badge {
	width: 40px;
	height: 14px;
	.box-shadow(0 1px 4px @clear-smoke-30);

	a {
		text-shadow: 0 1px 1px hsla(0, 100%, 100%, 0.3);
		display: block;
		width: 100%;
		height: 100%;
		line-height: 14px;
		color: inherit;
		text-align: center;
		font-size: @font-size-xs;
		font-weight: 500;

		&:hover,
		&:active {
			text-decoration: none;
		}
	}
}


// LEAFLET POPUP
// styles for the leaflet map bubble-style windows

.leaflet-popup {

	a {
		color: @text-color-primary;
	}

	.leaflet-popup-content-wrapper {
		padding: 0;
	}
	.leaflet-popup-content {
		margin: 0;
		.box-shadow(0 2px 4px @clear-smoke-30);
		border-radius: 3px;

		& > *:first-child {
			border-radius: 3px 3px 0 0;
		}
		& > *:last-child {
			border-radius: 0 0 3px 3px;
		}

		.field-info, .elevator-info {
			padding: 8px 30px 6px 10px;
			font-weight: 500;
			cursor: pointer;
			border-bottom: 2px solid transparent;
			min-width: 200px;

			h2 {
				font-weight: 500;
				font-size: @font-size-l;
				line-height: 1.2;
				margin: 0 0 2px;
			}
			.meta {
				margin: 0 0 6px;
				display: block;
				font-weight: 500;
				font-size: @font-size-s;
				color: @text-color-secondary;
			}
			.edit {
				position: absolute;
				top: 6px;
				right: 6px;
			}
		}

		.field-info {
			border-radius: 3px;
			background: @white;
			.meta {
				margin: 0 0 10px;
			}
		}

		.draw-close-shape {
			padding: 1em;
			font-weight: 500;
			cursor: pointer;
			border-bottom: 2px solid transparent;
			min-width: 200px;
			border-radius: 3px;
			background: @white;

			p {
				margin: 8px 0;
			}
		}

		.elevator-info {
			background: @smoke-97;
		}

		.blank-slate {
			border-top: 1px solid @separator-color;
		}
		.bids {
			background: @white;
			clear: both;
			cursor: pointer;
		}

		.bid {
			border-top: 1px solid @separator-color;
			overflow: auto;
			padding: 10px;
			font-weight: 500;
			font-size: @font-size-m;
			display: block;
			color: @text-color-primary;

			.meta {
				color: @text-color-secondary;
				display: inline-block;
				margin: 0 0 0 .5em;
				font-size: 0.8em;
				font-weight: 500;
			}

			.info {
				color: @text-color-primary;
				float: right;
			}
			&.crop-history {
				background: #f5f5f5;

				.info {
					color: @text-color-secondary;
				}
			}

		} // end bid


		.show-more {
			cursor: pointer;
			color: @text-color-secondary;
			font-weight: 500;
			text-align: center;
			padding: 3px;
			border-top: 1px solid @separator-color;
			background: @white;
			font-size: @font-size-xs;

			&:hover {
				background: @smoke-98;
			}
		}
		.control-group {
			margin-bottom: 10px;
		}
		.form-actions {
			padding-top: 10px;
			padding-bottom: 0;
			margin-top: 10px;
			margin-bottom: 0;
		}


	} // end popup-content

} // end popup


// LAYOUT CHANGES
// styles for app global layout elements like header, sidebar etc
// during field add or edit

.field-add-mode,
.field-edit-mode,
.field-bottom-bar,
.fullscreen {
	#header {
		top: -66px;
	}

	#nav {
		left: -210px;
	}

	#main {
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		padding-top: 0;
	}

	.leaflet-top {
		top: 0;
	}

	#fields-sidebar {
		display: none;
	}
}

.bottom-bar {
	padding: 0 1rem;
	position: fixed;
	width: 100%;
	height: @bottom-bar-height;
	background-color: white;
	bottom: -@bottom-bar-height;
	left: 0;
	right: 0;
	border-top: 1px solid @separator-color;
	align-items: center;
  	transition: all .3s ease-in-out;
	display: flex;
	box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.1);

	.btn {
		z-index: 100; // keep btns on top of message while keeping message centered
	}

	.message {
		text-align: center;
		z-index: 90;
		margin-top: 11px;
	}
}

.crosshair-cursor {
	&, .map-badge {
		cursor: url("../../../assets/images/onboarding/draw-cursor.png") 27 27, auto !important;

		&:active {
			cursor: grabbing !important;
			cursor: -webkit-grabbing !important;
			cursor: -moz-grabbing !important;
		}
	}

	&.complete-shape-cursor {
		cursor: pointer !important;
	}
}

.grab-cursor {
	cursor: auto !important;

	&:active {
		cursor: grabbing !important;
		cursor: -webkit-grabbing !important;
		cursor: -moz-grabbing !important;
	}
}

#map_canvas {
	circle.point, circle.midpoint {
		cursor: grab !important;
		cursor: -webkit-grab !important;
		cursor: -moz-grab !important;

		&:active {
			cursor: grabbing !important;
			cursor: -webkit-grabbing !important;
			cursor: -moz-grabbing !important;
		}
	}
}

.field-edit-mode {
	padding-bottom: @bottom-bar-height;

	#map-container {
		bottom: @bottom-bar-height;
	}

	#fields-sidebar-edit {
		right: 0;
	}

	.map-controls-bottom {
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.field-bottom-bar {
	#map-container {
		bottom: @bottom-bar-height;
	}

	.bottom-bar {
		bottom: 0;
	}
}

.fields-list-closed {
	#fields-sidebar {
		right: -(@sidebar-width);
	}

	#map-container {
		right: 0;
	}
}

.field-edit-mode .fields-list-closed #map-container {
	bottom: @bottom-bar-height;
}


// SHAPES
// styles for geometric shapes on top of maps

// field edit icons
.leaflet-editing-icon {
	margin: -8px 0 0 -8px !important;
	border-radius: 20px;
	width: 16px !important;
	height: 16px !important;
	border: none;
	.gradient( @smoke-96, @smoke-96, @smoke-70 );
	.box-shadow(0 2px 4px @clear-smoke-50 );

	&:hover:after {
		.before-after();
		width: 6px;
		height: 6px;
		left: 5px;
		top: 5px;
		border-radius: 6px;
		.gradient( @smoke-40, @smoke-40, @smoke-50 );
		.box-shadow(0 1px 1px white );
	}
}

// svg map shapes on map, field index, field detail
.map-shape {
	fill-opacity: 0.7;
	stroke-width: 0;
	fill-rule: evenodd;

	// when dropping a note, show field outlines only
	&.fl-note-edit-mode {
		pointer-events: none;
		stroke: white;
		stroke-opacity: 1;
		stroke-width: 3;
		fill-opacity: 0;
	}
}

.layer-shape {
	fill-opacity: 1;
	stroke-opacity: 0;
	transition: all .2s ease;

	&.hidden {
		opacity: 0;
		-webkit-transition: opacity .3s ease;
		-moz-transition: opacity .3s ease;
		-ms-transition: opacity .3s ease;
		transition: opacity .3s ease;
	}

	&:hover {
		stroke: white;
		stroke-opacity: 1;
	}
}

.layer-row {
	transition: all .2s ease;

	&:hover {
		opacity: 1;
		background: #eeeeee;
	}
}

.fl-faded {
	fill-opacity: .5;
	opacity: .5;
	transition: all .2s ease;
}

.field-boundary {
  opacity: 0.6;
  color: @smoke-80;
  fill: none;
}


// MAP DETAIL
// styles for zoomed-in big maps (soil, yield etc)

// style mixin
.map-detail-ui {
	position: absolute;
	background: white;
	border-radius: 3px;
	box-shadow: 0 2px 4px @clear-smoke-50;
	padding: 1em 1.5em;
}

.map-detail-title,
.map-detail-info,
.map-detail-close {
	z-index: 6;
}

.map-detail-title {
	.map-detail-ui();
	top: 1em;
	left: 1em;

	h4 {
		margin: 0;
	}
}

.map-detail-alert {
  .map-detail-ui();
  top: 1em;
  left: 50%;
  text-align: center;
  .transform( translate(-50%, 0) );

  h4 {
    margin: 0;
  }
}

.map-detail-info {
	.map-detail-ui();
	pointer-events: none;
	bottom: 2em;
	left: 50%;
	width: 600px;
	margin: 0 0 0 -300px;

	.swatch {
		width: 30px;
		height: 30px;
		float: left;
		margin: 0 1em 0 0;
	}

	.title,
	.sub {
		float: left;
		clear: both;
	}

	.title {
		font-size: @font-size-l;
		color: @text-color-primary;
	}

	.sub {
		font-size: @font-size-m;
		color: @text-color-secondary;
	}

}

.map-detail-close {
	.ui-control();

	position: absolute;
	background: @map-detail-dark-overlay;
	width: 80px;
	height: 80px;
	top: 0;
	right: 0;

	.close-x {
		color: white;
		opacity: 1;
		//font-size: 80px;
		font-weight: 300;
	}

}


.leaflet-image-layer {
	image-rendering: pixelated;
}


// PRINT

@media print {

	#map-wrapper {
	  .fields-list-closed #fields-sidebar,
	  .loading-placeholder {
	    display: none !important;
	  }
	}
	#fields-sidebar,
	#fields-sidebar-edit {
	  display: none;
	}
	#map-container,
	#map-container #map_canvas {
	  min-height: 100% !important;
	  width: 100% !important;
	}

}

// Mobile styles

@media only screen and (max-width: 480px) {
	#map-container .map-notification {
		display: none;
	}
}
