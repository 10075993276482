/*
*	SETTINGS
*
*	Styles for the settings page
*
*/

ul.settings-tabs {
  border-bottom: 1px solid hsl(216, 10%, 90%);
}

// SETTINGS ITEM
// a "thing" in a list on the settings page
// for ex: a list of peoepl
// TODO: replace with good table styles or other generalized component

.settings-item-wrapper {
    border-radius: 3px;
    background: @smoke-94;
    margin-bottom: 1rem;
    padding: .33em;
    border: 1px solid @smoke-90;
}
.settings-item {
    border: 1px solid @separator-color;
    padding: 1em;
    margin: 0 0 -1px;
    background: white;

    &.settings-item-hoverable:hover {
        cursor: pointer;
        background: @smoke-99;
    }

    .map-badge {
        margin: 5px 1.5em 5px 5px;
        float: left;
    }
    .icon {
        width: 40px;
        height: 40px;
        float: left;
        background: @smoke-90;
    }
    .chooser-icon {
        margin: 0;
        background: transparent;
    }
    .title {
        font-size: @font-size-l;
    }
    .flex-text-block {
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .sub {
        color: @text-color-secondary;
        font-size: @font-size-m;
    }
}

.flow-status-indicator {
    height: 1.5em;
    width: 1.5em;
    float: left;
    margin: 0.75em;
    margin-left: 0;
    border-radius: 50%;
    &.collecting-data {
        background-color: @green;
    }
    &.never-connected {
        background-color: @smoke-90;
    }
}

.users-header {
    text-align: center;
    img {
        margin: 0 auto;
        height: 80px;
    }

    h1 {
        font-size: @font-size-xxl;
        color: @text-color-primary;
    }

    p {
        max-width: 470px;
        margin: 0 auto;
        color: @text-color-secondary;
    }
}

.settings-content .heading-underline {
  border-bottom: none !important;
  .title {
    margin: 0 0 10px 0;
  }
}

.settings-content .heading-info {
  @media only screen and (min-width: @screen-md-min) {
    float: right;
  }
  line-height: 26px;
}
